import React from 'react'
import { useTranslation } from "react-i18next";
import './Footer.css'
function Footer() {
  const [t,i18n]=useTranslation();
  return (
    <footer>
      <div className='container'>
        <div className='row g-3' style={i18n.language == 'ar'?{flexDirection:'row-reverse'}:{flexDirection:'row'}}>
          <div className='col-lg-4'>
            <div className='footer-aboutus' style={i18n.language == 'ar'?{direction:'rtl'}:{direction:'ltr'}}>
              <h3>{t('footer.about_us.title')}</h3>
              <p>{t('footer.about_us.content')}</p>
            </div>
          </div>
          <div className='col-lg-4'>
            <div className='footer-contactus' style={i18n.language == 'ar'?{direction:'rtl'}:{direction:'ltr'}}>
              <h3>{t('footer.contact_us.title')}</h3>
              <p><i className="fa-solid fa-location-dot"></i> {t('footer.contact_us.content')}</p>
              <div className='social-icons'>
                <a href="https://wa.me/201023096900" target="_blank"><i className="fa-brands fa-whatsapp"></i></a>
                <a href='mailto:saadelweshahy@elforsanolive.com' target="_blank"><i className="fa-brands fa-google-plus-g"></i></a>
                <a href='tel:+201023096900' target="_blank"><i className="fa-solid fa-phone-volume"></i></a>
                <a href='https://www.facebook.com/elforsanolive?mibextid=ZbWKwL' target="_blank"><i className="fa-brands fa-facebook-messenger"></i></a>
              </div>
            </div>
          </div>
          <div className='col-lg-4'> 
            <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3446.261170057621!2d30.736564075561922!3d30.258138974816706!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzDCsDE1JzI5LjMiTiAzMMKwNDQnMjAuOSJF!5e0!3m2!1sen!2seg!4v1701034362495!5m2!1sen!2seg" width="100%" height="220px" style={{ border: 0 }}></iframe>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
